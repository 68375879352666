@font-face {
  font-family: "Century Gothic";
  src: url("./fonts/century-gothic/GOTHIC.TTF") format("truetype");
  font-display: swap;
}


* {
  font-family: 'Century Gothic', sans-serif;
}
body {
  margin: 0;
  font-family:'Century Gothic','Roboto', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

