* {
  scroll-margin-top: 100px;
}
@media (min-width: 1025px) {
  body {
    overflow-x: hidden;
  }
}


/* main header soustitre */
.borderSoustitre {
  border-left: 2px solid white;
  margin-left: 40%;
  margin-top: 0;
  padding-top: 1px;
  position: relative;
}
.borderSoustitreLink {
  text-decoration: none;
  color: white;
  position: absolute;
  bottom: -0.5rem;
  left: 10%;
}
.borderSoustitre:after {
  content: "";
  display: block;
  width: 9%;
  padding-top: 10px;
  border-bottom: 2px solid white;
}

.listItemAccueil li {
  color: orange;
}
.listItemAccueil li span {
  color: "white";
  font-size: 16;
}

.customBullet li {
  list-style: none;
  position: relative;
}
.customBullet li::before {
  color: orange;
  content: "\2022";
  position: absolute;
  left: -1.2em;
  line-height: normal;
  vertical-align: middle;
  font-weight: bolder;
}
.customBulletMenu li {
  list-style: none;
  position: relative;
}
.customBulletMenu li::before {
  color: orange;
  content: "\2022";
  position: absolute;
  left: -1.2em;
  line-height: normal;
  vertical-align: middle;
  font-weight: bolder;
}
.customBulletPresentation li {
  list-style: none;
  position: relative;
}
.customBulletPresentation li::before {
  color: #ffb300;
  content: "\2022";
  position: absolute;
  left: -1.2em;
  line-height: normal;
  vertical-align: middle;
  font-weight: bolder;
  margin-left: -1rem;
}
.cookieContainerClasses {
  justify-content: center !important;
}
